p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext,
p-calendar.ng-touched.ng-invalid > .p-calendar > .p-inputtext,
p-checkbox.ng-touched.ng-invalid > .p-checkbox > .p-checkbox-box,
p-chips.ng-touched.ng-invalid > .p-chips > .p-inputtext,
p-dropdown.ng-touched.ng-invalid > .p-dropdown,
p-inputmask.ng-touched.ng-invalid > .p-inputtext,
p-inputnumber.ng-touched.ng-invalid > .p-inputnumber > .p-inputtext,
p-inputswitch.ng-touched.ng-invalid > .p-inputswitch,
.p-inputtext.p-invalid,
.p-inputtext.ng-touched.ng-invalid,
p-listbox.ng-touched.ng-invalid > .p-listbox,
p-multiselect.ng-touched.ng-invalid > .p-multiselect,
p-radiobutton.ng-touched.ng-invalid > .p-radiobutton > .p-radiobutton-box,
p-selectbutton.ng-touched.ng-invalid > .p-selectbutton > .p-button,
p-togglebutton.ng-touched.ng-invalid > .p-togglebutton.p-button {
  @include invalid-input();
  &:focus,
  &.p-focus {
    box-shadow: 0 0 0 1px $inputErrorBorderColor !important;
  }
}

p-autocomplete.ng-touched.ng-invalid
  .p-autocomplete-dd
  button.p-autocomplete-dropdown,
p-calendar.ng-touched.ng-invalid .p-calendar-w-btn .p-datepicker-trigger,
p-inputnumber.ng-touched.ng-invalid
  .p-inputnumber-buttons-stacked
  .p-inputnumber-button {
  &:focus,
  &.p-focus {
    box-shadow: 0 0 0 1px $inputErrorBorderColor !important;
  }
}

.p-inputtext .p-float-label > .ng-invalid.ng-touched + label {
  color: $inputErrorBorderColor;
}
