//// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
//// @group button
/// $buttonPadding: 0.5rem 1rem;
$buttonPadding: 0.5rem 1rem;

//// Width of a button having an icon with no label
//// @group button
$buttonIconOnlyWidth: 2.357rem;

//// Padding of a button having an icon with no label
//// @group button
/// $buttonIconOnlyPadding: 0.5rem 0;
$buttonIconOnlyPadding: 0.5rem 0;

//// Background of a button
//// @group button
$buttonBg: $primaryColor;

//// Text color of a button
//// @group button
$buttonTextColor: $primaryTextColor;

//// Border of a button
//// @group button
/// $buttonBorder: 1px solid $primaryColor;
$buttonBorder: 1px solid $prtButtonBorder;

//// Background of a button in hover state
//// @group button
$buttonHoverBg: $primaryDarkColor;

//// Text color of a button in hover state
//// @group button
$buttonTextHoverColor: $primaryTextColor;

//// Border color of a button in hover state
//// @group button
$buttonHoverBorderColor: $primaryDarkColor;

//// Background of a button in pressed state
//// @group button
$buttonActiveBg: $primaryDarkerColor;

//// Color of a button in pressed state
//// @group button
$buttonTextActiveColor: $primaryTextColor;

//// Border color of a button in pressed state
//// @group button
$buttonActiveBorderColor: $primaryDarkerColor;

//// Shadow of a raised button
//// @group button
$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

//// Border radius of a rounded button
//// @group button
$roundedButtonBorderRadius: 2rem;

//// Alpha level of a text button background in hover state
//// @group button
$textButtonHoverBgOpacity: 0.04;

//// Alpha level of a text button background in active state
//// @group button
$textButtonActiveBgOpacity: 0.16;

//// Border style of a outlined button
//// @group button
$outlinedButtonBorder: 1px solid;

//// Text color of a plain text button
//// @group button
$plainButtonTextColor: #6c757d;

//// Background color of a plain text button in hover state
//// @group button
$plainButtonHoverBgColor: #e9ecef;

//// Background color of a plain text button in active state
//// @group button
$plainButtonActiveBgColor: #dee2e6;

//// Background of a secondary button
//// @group button
/// $secondaryButtonBg: #607d8b;
$secondaryButtonBg: #fafbfc;

//// Text color of a secondary button
//// @group button
/// $secondaryButtonTextColor: #ffffff;
$secondaryButtonTextColor: #24292e;

//// Border of a secondary button
//// @group button
$secondaryButtonBorder: 1px solid $borderColor400;

//// Background of a secondary button in hover state
//// @group button
/// $secondaryButtonHoverBg: #546e7a;#f6f6f7
$secondaryButtonHoverBg: #f6f6f7;

//// Text color of a secondary button in hover state
//// @group button
/// $secondaryButtonTextHoverColor: #ffffff;
$secondaryButtonTextHoverColor: black;

//// Border color of a secondary button in hover state
//// @group button
/// $secondaryButtonHoverBorderColor: #546e7a;
$secondaryButtonHoverBorderColor: $borderColor400;

//// Background of a secondary button in pressed state
//// @group button
/// $secondaryButtonActiveBg: #455a64;
$secondaryButtonActiveBg: #e9e9ec;

//// Text color of a secondary button in pressed state
//// @group button
/// $secondaryButtonTextActiveColor: #ffffff;
$secondaryButtonTextActiveColor: black;

//// Border color of a secondary button in pressed state
//// @group button
$secondaryButtonActiveBorderColor: #455a64;

//// Box shadow of a secondary button in focused state
//// @group button
/// $secondaryButtonFocusShadow: 0 0 0 0.2rem #b0bec5;
$secondaryButtonFocusShadow: $prtButtonFocusShadow;

//// Background of an info button
//// @group button
$infoButtonBg: #03a9f4;

//// Text color of an info button
//// @group button
$infoButtonTextColor: #ffffff;

//// Border of an info button
//// @group button
$infoButtonBorder: 1px solid #03a9f4;

//// Background of an info button in hover state
//// @group button
$infoButtonHoverBg: #039be5;

//// Text color of an info button in hover state
//// @group button
$infoButtonTextHoverColor: #ffffff;

//// Border color of an info button in hover state
//// @group button
$infoButtonHoverBorderColor: #039be5;

//// Background of an info button in pressed state
//// @group button
$infoButtonActiveBg: #0288d1;

//// Text color of an info button in pressed state
//// @group button
$infoButtonTextActiveColor: #ffffff;

//// Border color of an info button in pressed state
//// @group button
$infoButtonActiveBorderColor: #0288d1;

//// Box shadow of an info button in focused state
//// @group button
/// $infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);
$infoButtonFocusShadow: $prtButtonFocusShadow;

//// Background of a success button
//// @group button
/// $successButtonBg: #4CAF50;
$successButtonBg: $green-500;

//// Text color of a success button
//// @group button
$successButtonTextColor: #ffffff;

//// Border of a success button
//// @group button
$successButtonBorder: 1px solid #4caf50;

//// Background of a success button in hover state
//// @group button
/// $successButtonHoverBg: #43A047;
$successButtonHoverBg: $green-600;

//// Text color of a success button in hover state
//// @group button
$successButtonTextHoverColor: #ffffff;

//// Border color of a success button in hover state
//// @group button
$successButtonHoverBorderColor: #43a047;

//// Background of a success button in pressed state
//// @group button
/// $successButtonActiveBg: #388E3C;
$successButtonActiveBg: $green-700;

//// Text Color of a success button in pressed state
//// @group button
$successButtonTextActiveColor: #ffffff;

//// Border color of a success button in pressed state
//// @group button
$successButtonActiveBorderColor: #388e3c;

//// Box shadow of a success button in focused state
//// @group button
$successButtonFocusShadow: $prtButtonFocusShadow;

//// Background of a warning button
//// @group button
/// $warningButtonBg: #ffc107;
$warningButtonBg: $yellow-500;

//// Text color of a warning button
//// @group button
/// $warningButtonTextColor: $textColor;
$warningButtonTextColor: #ffffff;

//// Border of a warning button
//// @group button
/// $warningButtonBorder: 1px solid #ffc107;
$warningButtonBorder: 1px solid $yellow-500;

//// Background of a warning button in hover state
//// @group button
/// $warningButtonHoverBg: #ffb300;
$warningButtonHoverBg: $yellow-600;

//// Text color of a warning button in hover state
//// @group button
/// $warningButtonTextHoverColor: $textColor;
$warningButtonTextHoverColor: #ffffff;

//// Border color of a warning button in hover state
//// @group button
/// $warningButtonHoverBorderColor: #ffb300;
$warningButtonHoverBorderColor: $yellow-600;

//// Background of a warning button in pressed state
//// @group button
/// $warningButtonActiveBg: #ffa000;
$warningButtonActiveBg: $yellow-700;

//// Text color of a warning button in pressed state
//// @group button
/// $warningButtonTextActiveColor: $textColor;
$warningButtonTextActiveColor: #ffffff;

//// Border color of a warning button in pressed state
//// @group button
/// $warningButtonActiveBorderColor: #ffa000;
$warningButtonActiveBorderColor: $yellow-700;

//// Box shadow of a warning button in focused state
//// @group button
$warningButtonFocusShadow: $prtButtonFocusShadow;

//// Background of a help button
//// @group button
$helpButtonBg: #9c27b0;

//// Text color of a help button
//// @group button
$helpButtonTextColor: #ffffff;

//// Border of a help button
//// @group button
$helpButtonBorder: 1px solid #9c27b0;

//// Background of a help help in hover state
//// @group button
$helpButtonHoverBg: #8e24aa;

//// Text color of a help button in hover state
//// @group button
$helpButtonTextHoverColor: #ffffff;

//// Border color of a help button in hover state
//// @group button
$helpButtonHoverBorderColor: #8e24aa;

//// Background of a help button in pressed state
//// @group button
$helpButtonActiveBg: #7b1fa2;

//// Text color of a help button in pressed state
//// @group button
$helpButtonTextActiveColor: #ffffff;

//// Border color of a help button in pressed state
//// @group button
$helpButtonActiveBorderColor: #7b1fa2;

//// Box shadow of a help button in focused state
//// @group button
$helpButtonFocusShadow: $prtButtonFocusShadow;

//// Background of a danger button
//// @group button
/// $dangerButtonBg: #f44336;
$dangerButtonBg: $red-600;

//// Text color of a danger button
//// @group button
$dangerButtonTextColor: #ffffff;

//// Border of a danger button
//// @group button
$dangerButtonBorder: 1px solid #f44336;

//// Background of a danger button in hover state
//// @group button
/// $dangerButtonHoverBg: #e53935;
$dangerButtonHoverBg: $red-700;

//// Text color of a danger button in hover state
//// @group button
$dangerButtonTextHoverColor: #ffffff;

//// Border color of a danger button in hover state
//// @group button
$dangerButtonHoverBorderColor: #e53935;

//// Background of a danger button in pressed state
//// @group button
/// $dangerButtonActiveBg: #d32f2f;
$dangerButtonActiveBg: $red-800;

//// Text color of a danger button in pressed state
//// @group button
$dangerButtonTextActiveColor: #ffffff;

//// Border color of a danger button in pressed state
//// @group button
/// $dangerButtonActiveBorderColor: #d32f2f;
$dangerButtonActiveBorderColor: $red-800;

//// Box shadow of a danger button in focused state
//// @group button
$dangerButtonFocusShadow: $prtButtonFocusShadow;

//// Text color of a link button
//// @group button
$linkButtonColor: $primaryDarkerColor;

//// Text color of a link button in hover state
//// @group button
$linkButtonHoverColor: $primaryDarkerColor;

//// Text decoration of a link button in hover state
//// @group button
$linkButtonTextHoverDecoration: underline;

//// Box shadow of a link button in focused state
//// @group button
$linkButtonFocusShadow: $prtButtonFocusShadow;

//// Background of a toggle button
//// @group button
$toggleButtonBg: #ffffff;

//// Border of a toggle button
//// @group button
$toggleButtonBorder: 1px solid #ced4da;

//// Text color of a toggle button
//// @group button
$toggleButtonTextColor: $textColor;

//// Icon color of a toggle button
//// @group button
$toggleButtonIconColor: $textSecondaryColor;

//// Background of a toggle button in hover state
//// @group button
$toggleButtonHoverBg: #e9ecef;

//// Border color of a toggle button in hover state
//// @group button
$toggleButtonHoverBorderColor: #ced4da;

//// Text color of a toggle button in hover state
//// @group button
$toggleButtonTextHoverColor: $textColor;

//// Icon color of a toggle button in hover state
//// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

//// Background of a toggle button in selected state
//// @group button
$toggleButtonActiveBg: $primaryColor;

//// Border color of a toggle button in selected state
//// @group button
$toggleButtonActiveBorderColor: $primaryColor;

//// Text color of a toggle button in selected state
//// @group button
$toggleButtonTextActiveColor: $primaryTextColor;

//// Icon color of a toggle button in selected state
//// @group button
$toggleButtonIconActiveColor: $primaryTextColor;

//// Hover background of a toggle button in selected state
//// @group button
$toggleButtonActiveHoverBg: $primaryDarkColor;

//// Hover border color of a toggle button in selected state
//// @group button
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;

//// Hover text color of a toggle button in selected state
//// @group button
$toggleButtonTextActiveHoverColor: $primaryTextColor;

//// Hover icon of a toggle button in selected state
//// @group button
$toggleButtonIconActiveHoverColor: $primaryTextColor;
