/* Variables that might be used by other variable partials */

/// Theme Specific Variables
/// $primaryColor: #2196f3;
$primaryColor: $blue-500;
/// $primaryDarkColor: $blue-600;
$primaryDarkColor: $blue-550;
/// $primaryDarkerColor: $blue-700;
$primaryDarkerColor: $blue-600;
$primaryTextColor: #ffffff;

/* EXTENDED */

$primaryTextColor0: #ffffff;
$primaryTextColor50: $gray-50;
$primaryTextColor100: $gray-100;
$primaryTextColor200: $gray-200;
$primaryTextColor300: $gray-300;
$primaryTextColor400: $gray-400;
$primaryTextColor450: $gray-450;
$primaryTextColor500: $gray-500;
$primaryTextColor600: $gray-600;
$primaryTextColor700: $gray-700;
$primaryTextColor800: $gray-800;
$primaryTextColor900: $gray-900;

$primaryNewTextColor0: #ffffff;
$primaryNewTextColor50: $gray-new-50;
$primaryNewTextColor100: $gray-new-100;
$primaryNewTextColor200: $gray-new-200;
$primaryNewTextColor300: $gray-new-300;
$primaryNewTextColor400: $gray-new-400;
$primaryNewTextColor450: $gray-new-450;
$primaryNewTextColor500: $gray-new-500;
$primaryNewTextColor600: $gray-new-600;
$primaryNewTextColor700: $gray-new-700;
$primaryNewTextColor800: $gray-new-800;
$primaryNewTextColor900: $gray-new-900;

$borderColor300: $gray-300;
$borderColor400: $gray-400;

$appBg: #f6f6f6;
$appTextColor: $gray-900;
$fontFamilySecondary: Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$prtCardBg: white;
$prtCardShadow: 0px 2px 3px rgba(0, 0, 0, 0.03);
$mainNavBg: radial-gradient(
  264.06% 35138.67% at 11.77% -50%,
  #305aba 0%,
  #3e69ca 100%
);
$mainNavActiveBg: rgba(71, 112, 205, 1);
$mainNavHoverBg: rgba(71, 112, 205, 0.7);
$tableHeaderTextColorGray: $gray-500;
$prtMenuBg: white;
$prtPanelHeaderBg: #f8f9fa;
$prtDividerColor: $gray-300;
$prtButtonBorder: transparent;
$prtFocusOutlineColor: #6ea6f3;
$prtButtonFocusShadow: 0px 0px 0px 1px #6ea6f3;
$scrollbarThumbColor: #949494;

$frozenColumnBoxShadowColor: rgba(0, 0, 0, 0.1);
$lastFrozenColumnFromLeftBoxShadow: 6px 0 10px -4px $frozenColumnBoxShadowColor;
$lastFrozenColumnFromRightBoxShadow: -6px 0 10px -4px $frozenColumnBoxShadowColor;

/// $tw*: <tailwind utilities>;
/// Apply with interpolation:
///
/// $twAppBg: bg-gray-100;
/// html { @apply #{$twAppBg}; }

/* END EXTENDED */
