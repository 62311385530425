.p-button.p-component,
.p-selectbutton.p-component {
  @apply text-base;
  &:not(.p-button-text) {
    box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.04);
  }
  &:enabled:focus,
  &:not(.p-disabled):focus {
    border-color: $prtFocusOutlineColor !important;
    box-shadow: $prtButtonFocusShadow;
    z-index: 1;
  }
  &.p-button-sm {
    @apply text-sm;
    line-height: 1rem;
    padding-left: 9px;
    padding-right: 9px;
  }
}
.p-selectbutton.p-component {
  .p-button-label {
    @apply text-sm;
    line-height: 1rem;
    padding-left: 9px;
    padding-right: 9px;
  }
}

.p-button.p-button-secondary {
  border: 1px solid $borderColor400;
}

.p-button.p-button-secondary .p-button-icon {
  color: $blue-500;
}

.p-button .p-button-icon,
.p-button.p-button-sm .p-button-icon {
  &.pi-plus {
    font-size: 0.65em;
    line-height: 0.875rem;
  }
}
.p-button.p-button-sm .p-button-icon-left {
  padding-left: 4px;
}

.p-button-text {
  border: 0 !important;
}
