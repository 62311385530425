.p-inputswitch {
  width: $inputSwitchWidth;
  height: $inputSwitchHeight;

  .p-inputswitch-slider {
    background: $inputSwitchSliderOffBg;
    transition: $formElementTransition;
    border-radius: $inputSwitchBorderRadius;

    &:before {
      background: $inputSwitchHandleOffBg;
      width: $inputSwitchHandleWidth;
      height: $inputSwitchHandleHeight;
      left: $inputSwitchSliderPadding;
      margin-top: calc(-1 * $inputSwitchHandleHeight / 2);
      border-radius: $inputSwitchHandleBorderRadius;
      transition-duration: $transitionDuration;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider:before {
      transform: translateX($inputSwitchHandleWidth);
      background: $inputSwitchHandleOnBg;
    }

    .p-inputswitch-slider {
      background: $inputSwitchSliderOnBg;
    }

    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: $inputSwitchSliderOnHoverBg;
      }
    }
  }

  &.p-focus {
    .p-inputswitch-slider {
      @include focused();
    }
  }

  &:not(.p-disabled):hover {
    .p-inputswitch-slider {
      background: $inputSwitchSliderOffHoverBg;
    }
  }

  &.p-error,
  &.p-invalid {
    @include invalid-input();
  }
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  @include invalid-input();
}
