/* Customizations to the designer theme should be defined here */
/// - "p-*" classes overrides
/* For all colors used here variable must be extracted to _variables to be overridable in theme */

@import './extensions/extensions-buttons';
@import './extensions/extensions-checkbox';
@import './extensions/extensions-form';
@import './extensions/extensions-validation';

.p-dataview {
  .override-data-view-header-quotations {
    .pi-th-large::before {
      content: '\e949' !important;
    }
  }
  .p-dataview-header {
    background: none;
  }
}

.timeline-left-align {
  .p-timeline-event-opposite {
    flex: 0;
  }
}

.p-menu-item-link-lg .p-menuitem-link {
  @apply text-lg;
}

.p-breadcrumb ul li .p-menuitem-link {
  padding: 0.5rem 0.25rem;
  display: flex;
  align-items: center;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  font-size: 0.75em;
  padding-right: 12px;
  line-height: 11px;
  margin-top: 1px;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.25rem;
}

.p-breadcrumb-chevron::before {
  content: '/' !important;
  font-weight: 500;
  font-family: var(--font-family);
}

.p-tabview-panels-p-0 .p-tabview-panels {
  @apply p-0;
}

.p-inputgroup .p-float-label {
  p-inputnumber,
  p-dropdown {
    .p-component {
      border-radius: 0;
      &:hover {
        z-index: 2;
      }
      &:active,
      &:focus,
      &.p-focus {
        z-index: 3 !important;
      }
    }
  }
  &:first-child {
    p-inputnumber,
    p-dropdown {
      .p-component {
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
      }
    }
  }
  &:last-child {
    p-inputnumber,
    p-dropdown {
      .p-component {
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
      }
    }
  }
}

.card-tabview {
  .p-tabview-nav {
    .p-tabview-nav-link {
      @apply m-0 text-base leading-none pt-7 #{!important};
      border-bottom-width: 3px !important;
    }
    border-bottom: 1px solid $borderColor300;
  }
}

.shorter-tables {
  .p-datatable-thead > tr > th {
    @apply py-3 #{!important};
  }
  .p-datatable .p-datatable-tbody > tr > td {
    @apply py-2 #{!important};
  }
  .p-datatable .p-paginator {
    @apply py-1 text-xs-plus #{!important};
  }
}

.p-breadcrumb .p-menuitem-text,
.p-breadcrumb-chevron {
  @apply font-medium;
  @apply text-base #{!important};
}

.da-tab-separator {
  margin-left: 1.5rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: -0.75rem;
    top: 0.6rem;
    bottom: 0.6rem;
    border-left: 1px solid $dividerColor;
  }
}

.chip-form-readonly {
  @apply bg-gray-100 text-gray-800 border border-gray-300;
}

.p-fileupload .p-fileupload-row > div {
  padding: 0.25rem;
  &:not(:nth-child(2)) {
    width: auto;
    flex: 0 0 auto;
  }
  > img {
    margin-right: 0.25rem;
  }
  &:nth-child(2) {
    overflow-wrap: break-word;
  }
}

.p-badge {
  @apply rounded-full #{!important};
}

.table-border-bottom-none {
  .p-datatable-tbody > tr:last-child > td {
    border-bottom-width: 0;
  }
}

.no-x-padding {
  .p-datatable-wrapper > table {
    th:first-child,
    td:first-child {
      padding-left: 0;
    }
    th:last-child,
    td:last-child {
      padding-right: 0;
    }
  }
}

.p-datatable .p-datatable-tbody > tr.dirty {
  @apply bg-blue-100 dark:bg-blue-900;
}

.gray-header-table {
  .p-datatable {
    .p-datatable-thead > tr > th {
      @apply font-medium text-primary-500 text-xs py-5;
    }
  }
}

.da-table {
  .p-datatable .p-datatable-tbody > tr > td {
    @apply text-xs-plus py-2;
    height: 3.125rem;
    word-break: normal;
  }
  /// todo revise if applicablle to all badges
  .p-badge {
    @apply h-5 leading-5 font-medium;
  }
}
.po-table {
  .p-datatable .p-datatable-tbody > tr > td {
    @apply font-medium;
  }
}

.borderless-table {
  .p-datatable {
    .p-datatable-thead > tr > th {
      @apply font-medium text-sm text-primary-500 py-2;
    }
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      @apply pl-0 pr-2;
      &:last-child {
        @apply pr-0;
      }
    }
    .p-datatable-tbody > tr > td {
      padding-top: 14px;
      padding-bottom: 14px;
      @apply border-0;
    }
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    .p-tabmenuitem {
      .p-menuitem-link {
        box-shadow: none !important;
        @include font-display;
        transition: border-color $transitionDuration,
          box-shadow $transitionDuration;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 24px 18px;
      }
    }
    .p-tabmenuitem:not(.p-highlight) {
      .p-menuitem-link:not(.p-disabled):focus {
        border-color: $borderColor400;
      }
    }
  }
}
.p-tabview {
  .p-tabview-nav {
    li {
      .p-tabview-nav-link {
        box-shadow: none !important;
        @include font-display;
        transition: border-color $transitionDuration,
          box-shadow $transitionDuration;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &:not(.p-highlight) .p-tabview-nav-link:not(.p-disabled):focus {
        border-color: $borderColor400;
      }
    }
  }
}

.gray-search {
  &.p-input-icon-left {
    > .p-inputtext {
      @apply pl-9;
    }
    > i:first-of-type {
      @apply left-3 text-primary-500;
    }
  }
  input {
    @apply bg-primary-100 border-primary-200;
  }
}

p-chip .gradient-chip {
  @apply text-primary-700 border border-primary-400 bg-gradient-to-b from-primary rounded-full;
  --tw-gradient-to: #e5e5e5 !important;
}

.dark p-chip .gradient-chip {
  --tw-gradient-to: #3a3a3a !important;
}

p-chip .pi-chip-remove-icon.pi-times-circle {
  font-size: 0.8em;
  padding: 3px;
  &:before {
    content: '\e90b' !important;
  }
}

.dark {
  .p-button.p-button-secondary .p-button-icon {
    color: $blue-500;
  }

  p-dynamicdialog .p-dialog-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}

.p-inputgroup {
  div + div {
    margin-left: -1px;
  }
  input {
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    &:active,
    &:focus {
      z-index: 3 !important;
    }
  }
}

.p-fileupload-choose {
  color: $secondaryButtonTextColor !important;
}

.progress-bar {
  .p-progressbar {
    height: 4px;
    min-width: 20px;
    @apply rounded-full;
    .p-progressbar-value {
      @apply rounded-full  bg-teal-400;
    }
  }
}
.p-dialog {
  overflow: hidden;
}

.p-accordion-header-link:focus:not(:focus-visible) {
  outline: none;
  outline-offset: auto !important;
  box-shadow: none !important;
}

.hide-first-skeleton-column {
  .table-loading-row {
    td:first-child > * {
      visibility: hidden;
    }
    td:last-child {
      padding-right: 2rem !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .p-datatable .p-datatable-tbody > tr > td {
    white-space: nowrap;
  }
  .p-datatable .p-datatable-thead > tr > th {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 768px) {
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    padding: 12px 8px;
    font-size: 14px;
  }
  .p-tabview .p-tabview-nav {
    @apply px-3.5;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 18px 8px;
    font-size: 14px !important;
  }
  .p-menu {
    background: initial;
    border: none;
  }
  .p-menu .p-menuitem-link .p-menuitem-text {
    color: #fff;
  }

  .p-tabview .p-tabview-panels {
    @apply px-3.5;
  }
}

.p-overlaypanel {
  &.overlay-panel-full-width {
    right: 0px;
    width: 90%;
    left: 0px !important;
    max-width: 1100px;
    margin: auto !important;
  }
  &.overlay-panel-hide-arrow {
    &:before,
    &:after {
      display: none !important;
    }
  }
  &.overlay-panel-common {
    @apply border border-primary-300 border-solid;
    &:after,
    &:before {
      display: none;
    }
  }
  &.remarks-panel {
    max-height: calc(100vh - 6rem);
    overflow: auto;
  }
  &.filters-panel {
    @apply border border-primary-300 border-solid;
    &:after,
    &:before {
      border-bottom-color: transparent;
    }
    .p-overlaypanel-content {
      @apply p-0 rounded-b #{!important};
    }
    .filters-header {
      @apply bg-primary-100 flex justify-between items-center p-2 rounded-t;
      .title {
        @apply text-sm-plus;
      }
    }

     .p-accordion {
       p-accordiontab:last-child {
         .p-accordion-content {
           @apply rounded-b #{!important};
         }
       }
       .p-accordion-header {
         .p-accordion-header-link {
           @apply border-l-0 border-r-0;
           @apply p-3 bg-primary text-primary-700 rounded-none;

           .p-accordion-toggle-icon {
             @apply text-primary-500;
           }
         }

         &:not(.p-highlight):not(.p-disabled):hover {
           .p-accordion-header-link {
             @apply bg-primary-50;
           }
         }

         &:not(.p-disabled).p-highlight {
           .p-accordion-header-link {
             @apply bg-primary;
           }

           &:hover {
             .p-accordion-header-link {
               @apply bg-primary-50;
             }
           }
         }
       }

       .p-accordion-content {
         @apply border-l-0 border-r-0;
         @apply py-3 px-2.5 rounded-none bg-primary-100;
       }
     }
  }
}

.filters-active {
  @apply text-blue-600 border-blue-200 shadow font-semibold #{!important};
  .p-button-icon {
    @apply text-blue-600 #{!important};
  }
}

.shipment-sidebar {
  &.p-sidebar-right {
    @apply py-3 pr-2;
    .p-sidebar-content {
      overflow-y: scroll !important;

      @apply mt-1;
    }
    &.p-sidebar-lg {
      width: 52rem;
    }
  }
}
@media screen and (max-width: 52em) {
  .shipment-sidebar {
    &.p-sidebar-right.p-sidebar-lg {
      width: 100%;
    }
  }
}

.p-tabview-nav {
  .tabview-nav-link-py-0 {
    .p-tabview-nav-link {
      @apply py-0 h-full #{!important};
    }
  }
}

.reset-pointer-events {
  .p-inputswitch-slider {
    cursor: pointer !important;
  }
}

///INPUT EXTENSIONS

.p-multiselect {
  .pi.pi-spin.pi-spinner {
    color: #3a3a3a;
  }
}
.p-dropdown {
  .pi.pi-spin.pi-spinner {
    color: #3a3a3a;
  }
}
.p-autocomplete {
  &.w-full {
    ul.p-autocomplete-multiple-container {
      width: 100%;
    }
  }
}

.autocomplete-clear {
  @apply absolute top-0 right-11 h-full flex items-center;
}

.clear-icon {
  @apply ml-1 cursor-pointer;
  color: $inputIconColor;
  right: $inputGroupAddOnMinWidth;
  &:hover {
    color: $primaryTextColor400;
  }
}

.p-dropdown-label.p-inputtext {
  box-shadow: none;
}

.table-header-filters {
  .p-button.p-button-icon-only {
    @apply w-8;
  }
  .p-autocomplete-dd .p-autocomplete-input {
    @apply pr-14;
  }
  .p-multiselect-trigger {
    @apply w-8;
  }
  .p-inputtext,
  .p-multiselect-label {
    @apply text-xs-plus py-1.5 px-1.5;
  }
  .autocomplete-clear {
    @apply right-9;
  }
}

.smaller-table-filters.table-header-filters {
  .p-inputtext {
    @apply text-2xs px-1.5;
    @include font-display;
  }
  .p-button.p-button-icon-only {
    @apply w-6;
  }
  .p-button.p-component,
  .autocomplete-clear {
    .pi {
      @apply text-xs;
    }
  }
  .autocomplete-clear {
    @apply right-7;
  }
  .p-autocomplete-dd .p-autocomplete-input {
    @apply pr-11;
  }
  .clear-icon:hover {
    color: $primaryTextColor500;
  }
}

.p-confirm-dialog {
  .p-dialog-content {
    @apply py-4;
    padding-right: 30px;
  }

  .p-dialog-header,
  .p-dialog-footer {
    @apply py-4 px-6;
  }
}

p-dynamicdialog .p-dialog-mask {
  top: 0px;
  bottom: 0px;
  height: auto;
  padding-top: 64px;
  padding-left: 14px;
  padding-right: 14px;
  background: rgba(0, 0, 0, 0.25) !important;
}
