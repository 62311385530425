@use 'sass:meta';
/// https:///sass-lang.com/documentation/breaking-changes/css-vars

:root {
  --surface-a: #{$prtCardBg};
  --surface-d: #dee2e6;
  --text-color: #{$textColor};
  --text-color-secondary: #{$textSecondaryColor};
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};

  --font-family: #{meta.inspect($fontFamily)};

  /* Expose here what is needed by angular component style or tailwind utility */
  /* EXTENDED */

  /// --fontFamilyDisplay: #{meta.inspect($fontFamilyDisplay)};
  --fontFamilySecondary: #{meta.inspect($fontFamilySecondary)};
  --mainNavBg: #{$mainNavBg};
  --mainNavActiveBg: #{$mainNavActiveBg};
  --mainNavHoverBg: #{$mainNavHoverBg};
  --borderColor300: #{$borderColor300};
  --borderRadius: #{$borderRadius};
  --sectionNumberConnectorColor: #{$primaryTextColor100};
  --errorColor: #{$errorColor};
  --surfaceColor0: #{$prtCardBg};
  --surfaceColor100: #{$appBg};
  --surfaceColor200: #fff;
  --primaryTextColor0: #{$primaryTextColor0};
  --primaryTextColor50: #{$primaryTextColor50};
  --primaryTextColor100: #{$primaryTextColor100};
  --primaryTextColor200: #{$primaryTextColor200};
  --primaryTextColor300: #{$primaryTextColor300};
  --primaryTextColor400: #{$primaryTextColor400};
  --primaryTextColor450: #{$primaryTextColor450};
  --primaryTextColor500: #{$primaryTextColor500};
  --primaryTextColor600: #{$primaryTextColor600};
  --primaryTextColor700: #{$primaryTextColor700};
  --primaryTextColor800: #{$primaryTextColor800};
  --primaryTextColor900: #{$primaryTextColor900};
  ///temporary
  --primaryNewTextColor0: #{$primaryNewTextColor0};
  --primaryNewTextColor50: #{$primaryNewTextColor50};
  --primaryNewTextColor100: #{$primaryNewTextColor100};
  --primaryNewTextColor200: #{$primaryNewTextColor200};
  --primaryNewTextColor300: #{$primaryNewTextColor300};
  --primaryNewTextColor400: #{$primaryNewTextColor400};
  --primaryNewTextColor450: #{$primaryNewTextColor450};
  --primaryNewTextColor500: #{$primaryNewTextColor500};
  --primaryNewTextColor600: #{$primaryNewTextColor600};
  --primaryNewTextColor700: #{$primaryNewTextColor700};
  --primaryNewTextColor800: #{$primaryNewTextColor800};
  --primaryNewTextColor900: #{$primaryTextColor900};
  /* END EXTENDED */
}
