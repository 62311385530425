@import '../common/forms';

/* Non primeng related */
/* For all colors used here variable must be extracted to _variables to be overridable in theme */
.font-display {
  @include font-display;
}

html {
  background-color: $appBg;
  width: 100vw;
  overflow-x: hidden;
  color: $appTextColor;
  font-display: block;
  * {
    scroll-snap-margin-top: 6rem;
  }
}

plu-root {
  height: 100vh;
  @apply flex flex-col;
}

.plu-container {
  @apply container max-w-7xl mx-auto px-4 sm:px-6;
}

h1 {
  @apply text-2xl mb-2 text-primary-800 font-semibold-minus;
  @include font-display;
}

h2 {
  @apply font-semibold-minus text-base text-primary-500;
  @include font-display;
}

h3 {
  @apply font-medium text-base text-primary-600;
}

.card {
  @apply px-7 py-6 mt-6 rounded-lg overflow-hidden w-full border;
  background-color: $prtCardBg;

  ///todo neither of tailwind shadows
  box-shadow: $prtCardShadow;

  .card-section {
    @apply px-7 py-6;
    @apply -mx-7;
    &:first-child {
      @apply -mt-6;
    }
    &:last-child {
      @apply -mb-6;
    }
    + .card-section {
      @apply border-t;
    }
  }
  .narrow-content > * {
    @apply block max-w-4xl mx-auto;
  }
  .vertical-form {
    @apply py-11 relative;

    label {
      &:not(.p-checkbox-label, .p-radiobutton-label) {
        @apply mb-1;
      }

      @apply block;
    }
    .p-error {
      @apply block mt-1;
    }
    .required {
      label {
        @apply font-bold;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .card {
    @apply px-4;
    .card-section {
      @apply px-4 -mx-4;
    }
  }
}

@media only screen and (max-width: 768px) {
  .card {
    @apply px-3.5;
    .card-section {
      @apply px-3.5 -mx-3.5;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/// firefox
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbarThumbColor transparent;
}

.hide-table-horizontal-scrollbar {
  .p-datatable-wrapper {
    overflow: auto;
    scrollbar-width: none;
  }

  .p-datatable-wrapper::-webkit-scrollbar {
    height: 0px;
    width: 8px;
  }

  .p-datatable-wrapper::-webkit-scrollbar-track {
    border-radius: 0;
    background: transparent;
  }

  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: $scrollbarThumbColor;
  }
}

.group-radio-button {
    .p-radiobutton-box {
      border: 2px solid $blue-500 !important;
    }

    .p-radiobutton-icon {
      background-color: $blue-500 !important;
    }
}

.multiple-cargos-cell {
  @apply w-full grid grid-cols-1 auto-rows-fr;
}