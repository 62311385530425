/// Could not find a good way to use common config with Tailwind

$transparent: transparent;
$current: currentColor;
$black: #000;
$white: #fff;
$gray-50: #f9fafb;
$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-450: #c6ccd5;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #111827;
$red-50: #fef2f2;
$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #fca5a5;
$red-400: #f87171;
$red-500: #f56565;
$red-600: #dc2626;
$red-700: #b91c1c;
$red-800: #991b1b;
$red-900: #7f1d1d;
$yellow-50: #fffbeb;
$yellow-100: #fef3c7;
$yellow-200: #fde68a;
$yellow-300: #fcd34d;
$yellow-400: #fbbf24;
$yellow-500: #ed8936;
$yellow-600: #d97706;
$yellow-700: #b45309;
$yellow-800: #92400e;
$yellow-900: #78350f;

$lime-50: #f7fee7;
$lime-100: #ecfccb;
$lime-200: #d9f99d;
$lime-300: #bef264;
$lime-400: #a3e635;
$lime-500: #84cc16;
$lime-600: #65a30d;
$lime-700: #4d7c0f;
$lime-800: #3f6212;
$lime-900: #365314;

$green-50: #ecfdf5;
$green-100: #d1fae5;
$green-200: #a7f3d0;
$green-300: #6ee7b7;
$green-400: #34d399;
$green-500: #10b981;
$green-600: #059669;
$green-700: #047857;
$green-800: #065f46;
$green-900: #064e3b;
$blue-50: #eff6ff;
$blue-100: #ebf8ff;
$blue-200: #bfdbfe;
$blue-300: #90cdf4;
$blue-400: #63b3ed;
$blue-500: #4299e1;
$blue-550: #367bec;
$blue-600: #3182ce;
$blue-700: #2b6cb0;
$blue-800: #1e40af;
$blue-900: #2a4365;
$indigo-50: #eef2ff;
$indigo-100: #e0e7ff;
$indigo-200: #c7d2fe;
$indigo-300: #a5b4fc;
$indigo-400: #818cf8;
$indigo-500: #6366f1;
$indigo-600: #4f46e5;
$indigo-700: #4338ca;
$indigo-800: #3730a3;
$indigo-900: #312e81;
$purple-50: #f5f3ff;
$purple-100: #ede9fe;
$purple-200: #ddd6fe;
$purple-300: #c4b5fd;
$purple-400: #a78bfa;
$purple-500: #8b5cf6;
$purple-600: #7c3aed;
$purple-700: #6d28d9;
$purple-800: #5b21b6;
$purple-900: #4c1d95;
$pink-50: #fdf2f8;
$pink-100: #fce7f3;
$pink-200: #fbcfe8;
$pink-300: #f9a8d4;
$pink-400: #f472b6;
$pink-500: #ec4899;
$pink-600: #db2777;
$pink-700: #be185d;
$pink-800: #9d174d;
$pink-900: #831843;

$blue-new-50: #eff6ff;
$blue-new-100: #dbeafe;
$blue-new-200: #bfdbfe;
$blue-new-300: #93c5fd;
$blue-new-400: #60a5fa;
$blue-new-500: #3b82f6;
$blue-new-550: #3078ec;
$blue-new-600: #2563eb;
$blue-new-700: #1d4ed8;
$blue-new-800: #1e40af;
$blue-new-900: #1e3a8a;

$gray-new-50: #f9fafb;
$gray-new-100: #f3f4f6;
$gray-new-200: #e5e7eb;
$gray-new-300: #d1d5db;
$gray-new-400: #9ca3af;
$gray-new-450: #89909b;
$gray-new-500: #6b7280;
$gray-new-600: #4b5563;
$gray-new-700: #374151;
$gray-new-800: #1f2937;
$gray-new-900: #111827;
