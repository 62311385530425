.fc {
  .fc-view-container {
    th {
      background: $panelHeaderBg;
      border: $panelHeaderBorder;
      color: $panelHeaderTextColor;
    }

    td {
      &.fc-widget-content {
        background: $panelContentBg;
        border: $panelContentBorder;
        color: $panelContentTextColor;
      }

      &.fc-head-container {
        border: $panelHeaderBorder;
      }
    }

    .fc-row {
      border-right: $panelHeaderBorder;
    }

    .fc-event {
      background: $fullCalendarEventBg;
      border: $fullCalendarEventBorder;
      color: $fullCalendarEventTextColor;
    }

    .fc-divider {
      background: $panelHeaderBg;
      border: $panelHeaderBorder;
    }
  }

  .fc-toolbar {
    .fc-button {
      color: $buttonTextColor;
      background: $buttonBg;
      border: $buttonBorder;
      font-size: $fontSize;
      transition: $formElementTransition;
      border-radius: $borderRadius;
      display: flex;
      align-items: center;

      &:enabled:hover {
        background: $buttonHoverBg;
        color: $buttonTextHoverColor;
        border-color: $buttonHoverBorderColor;
      }

      &:enabled:active {
        background: $buttonActiveBg;
        color: $buttonTextActiveColor;
        border-color: $buttonActiveBorderColor;

        &:focus {
          @include focused();
        }
      }

      .fc-icon-chevron-left {
        font-family: 'primeicons', sans-serif !important;
        text-indent: 0;
        @include icon_override('\e900');
        font-size: $primeIconFontSize;
      }

      .fc-icon-chevron-right {
        font-family: 'primeicons', sans-serif !important;
        text-indent: 0;
        @include icon_override('\e901');
        font-size: $primeIconFontSize;
      }

      &:focus {
        @include focused();
      }

      &.fc-dayGridMonth-button,
      &.fc-timeGridWeek-button,
      &.fc-timeGridDay-button {
        background: $toggleButtonBg;
        border: $toggleButtonBorder;
        color: $toggleButtonTextColor;
        transition: $formElementTransition;

        &:hover {
          background: $toggleButtonHoverBg;
          border-color: $toggleButtonHoverBorderColor;
          color: $toggleButtonTextHoverColor;
        }

        &.fc-button-active {
          background: $toggleButtonActiveBg;
          border-color: $toggleButtonActiveBorderColor;
          color: $toggleButtonTextActiveColor;

          &:hover {
            background: $toggleButtonActiveHoverBg;
            border-color: $toggleButtonActiveHoverBorderColor;
            color: $toggleButtonTextActiveHoverColor;
          }
        }

        &:focus {
          @include focused();
          z-index: 1;
        }
      }
    }

    .fc-button-group {
      .fc-button {
        border-radius: 0;

        &:first-child {
          border-top-left-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }

        &:last-child {
          border-top-right-radius: $borderRadius;
          border-bottom-right-radius: $borderRadius;
        }
      }
    }
  }
}
