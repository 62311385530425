p-checkbox {
  &:hover {
    .p-checkbox {
      &.p-checkbox-danger {
        &:not(.p-checkbox-disabled) {
          .p-checkbox-box {
            border-color: $red-500 !important;
          }
        }
      }
      &.p-checkbox-warn {
        &:not(.p-checkbox-disabled) {
          .p-checkbox-box {
            border-color: $yellow-500 !important;
          }
        }
      }
      &.p-checkbox-indigo {
        &:not(.p-checkbox-disabled) {
          .p-checkbox-box {
            border-color: $indigo-500 !important;
          }
        }
      }
      &.p-checkbox-lime {
        &:not(.p-checkbox-disabled) {
          .p-checkbox-box {
            border-color: $lime-500 !important;
          }
        }
      }
      &.p-checkbox-green {
        &:not(.p-checkbox-disabled) {
          .p-checkbox-box {
            border-color: $green-500 !important;
          }
        }
      }

      &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
          border-color: $inputHoverBorderColor;
        }
      }
    }
  }
}
.p-checkbox {
  &.p-checkbox-danger {
    .p-checkbox-box {
      &.p-highlight {
        border-color: $red-500 !important;
        background: $red-500 !important;
      }
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $red-500 !important;
        }
        &.p-focus {
          border-color: $red-500 !important;
        }
        &.p-highlight:hover {
          border-color: $red-500 !important;
          background: $red-500 !important;
          color: $red-500 !important;
        }
      }
    }
  }
  &.p-checkbox-warn {
    .p-checkbox-box {
      &.p-highlight {
        border-color: $yellow-500 !important;
        background: $yellow-500 !important;
      }
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $yellow-500 !important;
        }
        &.p-focus {
          border-color: $yellow-500 !important;
        }
        &.p-highlight:hover {
          border-color: $yellow-500 !important;
          background: $yellow-500 !important;
          color: $yellow-500 !important;
        }
      }
    }
  }
  &.p-checkbox-indigo {
    .p-checkbox-box {
      &.p-highlight {
        border-color: $indigo-500 !important;
        background: $indigo-500 !important;
      }
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $indigo-500 !important;
        }
        &.p-focus {
          border-color: $indigo-500 !important;
        }
        &.p-highlight:hover {
          border-color: $indigo-500 !important;
          background: $indigo-500 !important;
          color: $indigo-500 !important;
        }
      }
    }
  }
  &.p-checkbox-lime {
    .p-checkbox-box {
      &.p-highlight {
        border-color: $lime-500 !important;
        background: $lime-500 !important;
      }
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $lime-500 !important;
        }
        &.p-focus {
          border-color: $lime-500 !important;
        }
        &.p-highlight:hover {
          border-color: $lime-500 !important;
          background: $lime-500 !important;
          color: $lime-500 !important;
        }
      }
    }
  }
  &.p-checkbox-green {
    .p-checkbox-box {
      &.p-highlight {
        border-color: $green-500 !important;
        background: $green-500 !important;
      }
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $green-500 !important;
        }
        &.p-focus {
          border-color: $green-500 !important;
        }
        &.p-highlight:hover {
          border-color: $green-500 !important;
          background: $green-500 !important;
          color: $green-500 !important;
        }
      }
    }
  }
}
