@use '../common/mixins' as prtMixins;

.numbered-form {
  $leftSectionWidth: 44px;
  $leftSectionMargin: 32px;

  .form-sections > *:last-child .form-section-header:after {
    content: none;
  }
  .form-section-header {
    @include prtMixins.font-display;
    @apply mb-5 text-2xl font-bold flex items-center;
    ///connector
    &:after {
      content: '';
      @apply absolute top-11 h-full;
      border-left: 2px solid var(--sectionNumberConnectorColor);
      left: calc((#{$leftSectionWidth} + 4px) / 2);
    }
    .section-number {
      @apply text-xl-plus font-bold rounded-full border-2 flex justify-center items-center box-content relative z-10;
      @apply text-primary-700 border-primary-700;
      background: var(--surface-a);
      width: $leftSectionWidth;
      height: $leftSectionWidth;
      margin-right: $leftSectionMargin;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
    }
    &.completed {
      .section-number {
        @apply text-primary border-blue-500 bg-blue-500 overflow-hidden;
        &:after {
          font-family: 'primeicons', sans-serif;
          content: '\e909';
          @apply absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center  bg-blue-500 text-xl;
        }
      }
    }
  }
  .form-section {
    margin-left: calc(#{$leftSectionWidth} + #{$leftSectionMargin} + 4px);
  }
  .footer {
    padding-left: calc(#{$leftSectionWidth} + #{$leftSectionMargin} + 4px);
  }
  .form-section-grid {
    @apply grid grid-cols-8 gap-7;
  }
  .form-section-input-group {
    @apply col-span-4 sm:col-span-4;
    label {
      @apply text-sm-plus;
      @include prtMixins.font-display;
    }
  }
}

@media only screen and (max-width: 768px) {
  .numbered-form .form-section-grid {
    display: inherit;
  }
  .numbered-form .form-section-input-group {
    grid-column: inherit;
    display: block;
    margin-top: 10px;
  }
  .numbered-form .form-section {
    margin-left: 45px;
  }
  .section-number + div {
    font-size: 16px;
  }
  .numbered-form .footer {
    padding-left: 0;
    display: flex;
  }
  .numbered-form .footer button {
    font-size: 12px;
    padding: 2px 8px;
  }
  .numbered-form .form-section-header .section-number {
    width: 30px;
    height: 30px;
    font-size: 16px;
    margin-right: 12px;
  }
  .numbered-form .form-section-header:after {
    left: calc((30px + 4px) / 2);
  }
}
