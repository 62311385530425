* {
  box-sizing: border-box;
}

.p-component {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

.p-component-overlay {
  background-color: $maskBg;
  transition-duration: $transitionDuration;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation $transitionDuration forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation $transitionDuration forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: $maskBg;
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: $maskBg;
  }
  to {
    background-color: transparent;
  }
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error,
.p-invalid {
  color: $errorColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-link {
  font-size: $fontSize;
  font-family: $fontFamily;
  border-radius: $borderRadius;

  &:focus {
    @include focused();
  }
}

.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-scroller-viewport
  > .p-scroller
  > .p-datatable-table
  > .p-datatable-thead {
  background: var(--surfaceColor200);
}
