/* Non primeng related */

.grid-settings-draggable-element {
  background-color: $gray-200 !important;
}

.port-call-detail-card-item-text {
  color: $gray-800;
}

.port-call-detail-card-background {
  background-color: $gray-300;
}

.port-call-detail-map-toggle-container {
  background-color: $gray-300;
}
