.p-inputtext {
  /// disable background transition
  transition: color $transitionDuration, border-color $transitionDuration,
    box-shadow $transitionDuration;

  &:enabled:hover {
    border-color: $inputHoverBorderColor;
  }
}

.p-inputgroup.force-hover:hover {
  .p-inputtext:enabled {
    border-color: $inputHoverBorderColor;
  }
}

.p-inputtext,
.p-dropdown,
.p-multiselect {
  @apply shadow-sm;
}

/// override tailwind resets
@include placeholder {
  color: $inputPlaceholderTextColor !important;
}

/// styling input buttons as secondary
.p-autocomplete-dd button.p-autocomplete-dropdown,
.p-calendar-w-btn .p-datepicker-trigger,
.p-inputnumber-buttons-stacked .p-inputnumber-button,
.p-fileupload-choose {
  background: $secondaryButtonBg;
  color: #718096;
  border: 1px solid $borderColor400 !important;
  box-shadow: none;
  .p-button-icon {
    color: #a0aec0;
  }
  &:enabled:hover,
  &:not(.p-disabled):hover {
    background: $secondaryButtonHoverBg;
    color: #718096;
  }
  &:enabled:active,
  &:not(.p-disabled):active {
    background: $secondaryButtonActiveBg !important;
    color: #718096;
  }
}

.p-autocomplete-dd button.p-autocomplete-dropdown,
.p-calendar-w-btn .p-datepicker-trigger,
.p-inputnumber-buttons-stacked .p-inputnumber-button {
  &:enabled:hover,
  &:not(.p-disabled):hover,
  &:enabled:focus,
  &:not(.p-disabled):focus {
    background: $secondaryButtonHoverBg;
    color: #718096;
    border-color: $borderColor400 !important;
  }
}

/// start - styling inputs with buttons for various states
///inputs
.p-inputnumber-buttons-stacked input.p-inputnumber-input,
.p-calendar-w-btn input.p-inputtext,
.p-autocomplete-dd .p-autocomplete-input {
  border-bottom-right-radius: $borderRadius !important;
  border-top-right-radius: $borderRadius !important;
}

///buttons
.p-inputnumber-buttons-stacked .p-inputnumber-button-group,
.p-calendar-w-btn button.p-datepicker-trigger,
.p-autocomplete-dd button.p-autocomplete-dropdown {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  margin-left: 0px;
  border-bottom-right-radius: calc(var(--borderRadius) - 1px) !important;
  border-top-right-radius: calc(var(--borderRadius) - 1px) !important;
  &:after {
    content: none;
  }
}

.p-inputnumber-buttons-stacked {
  position: relative;
  .p-inputnumber-button {
    border-right: none !important;
    &:after {
      content: none;
    }
  }
  .p-inputnumber-button:first-child {
    border-top: none !important;
    border-top-right-radius: calc(var(--borderRadius) - 1px) !important;
  }
  .p-inputnumber-button:last-child {
    border-top: none !important;
    border-bottom: none !important;
    border-bottom-right-radius: calc(var(--borderRadius) - 1px) !important;
  }
}
/// end - styling inputs with buttons for various states

.readonly.p-disabled,
.readonly.p-component:disabled {
  /// todo and any other needed
  &.p-inputtext {
    @apply bg-blue-50 dark:bg-blue-900 font-medium;
  }

  opacity: 1;
  @apply text-indigo-800 dark:text-indigo-300;
}

.plu-radio-list {
  @apply overflow-auto rounded-lg border flex flex-col divide-y;
  box-shadow: $prtCardShadow;
  p-radiobutton {
    @apply relative;
    label {
      @apply pl-12 py-3 pr-4 m-0 w-full text-sm cursor-pointer #{!important};
      &.p-radiobutton-label-active {
        @apply bg-blue-50 dark:bg-blue-900 text-indigo-800 dark:text-indigo-300;
      }
    }
    .p-radiobutton {
      @apply absolute top-0 left-0 h-full w-10 flex items-center justify-center pl-4;
      &.p-radiobutton-disabled {
        @apply cursor-default;
      }
    }
    &:hover {
      label:not(.p-disabled) {
        @apply bg-primary-200;
        &.p-radiobutton-label-active {
          @apply bg-primary-300;
        }
      }
      .p-radiobutton {
        .p-radiobutton-box {
          transition-duration: 0s;
          &:not(.p-disabled):not(.p-highlight) {
            border-color: $inputHoverBorderColor;
          }
          &.p-highlight {
            &:not(.p-disabled) {
              border-color: $radiobuttonActiveHoverBorderColor;
              background: $radiobuttonActiveHoverBg;
              color: $radiobuttonIconActiveHoverColor;
            }
          }
        }
      }
    }
  }
}

.search-inputgroup {
  input,
  button {
    &:hover {
      z-index: 2;
    }
    &:active,
    &:focus {
      z-index: 3 !important;
    }
  }
  input + button,
  .p-inputgroup + button {
    margin-left: -1px !important;
  }
}
