.p-tabview {
  .p-tabview-nav {
    background: $tabviewNavBg;
    border: $tabviewNavBorder;
    border-width: $tabviewNavBorderWidth;

    li {
      margin-right: 0;
      .p-tabview-nav-link {
        background: $tabviewHeaderBg;
        color: #6c757d;
        padding: 1.25rem;
        font-weight: 600;
        transition: box-shadow 0.2s;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background: $tabviewHeaderHoverBg;
          border-color: $tabviewHeaderHoverBorderColor;
          color: $tabviewHeaderTextHoverColor;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          background: $tabviewHeaderActiveBg;
          border-color: $tabviewHeaderActiveBorderColor;
          color: $tabviewHeaderTextActiveColor;
          border-width: 0 0 4px 0 !important;
          margin: 0 0 -2px 0;
        }
      }
    }
  }

  .p-tabview-left-icon {
    margin-right: $inlineSpacing;
  }

  .p-tabview-right-icon {
    margin-left: $inlineSpacing;
  }

  .p-tabview-close {
    margin-left: $inlineSpacing;
  }

  .p-tabview-panels {
    background: $tabviewContentBg;
    padding: 0 $tabviewContentPadding $tabviewContentPadding $tabviewContentPadding;
    border: $tabviewContentBorder;
    color: $tabviewContentTextColor;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
  }
}
