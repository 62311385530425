//// Padding of an inplace element
//// @group misc
$inplacePadding: $inputPadding;

//// Background of an inplace element in hover state
//// @group misc
$inplaceHoverBg: #e9ecef;

//// Text color of an inplace element in hover state
//// @group misc
$inplaceTextHoverColor: $textColor;

//// Background of a badge
//// @group misc
$badgeBg: $primaryColor;

//// Text color of a badge
//// @group misc
$badgeTextColor: $primaryTextColor;

//// Minimum width of a badge
//// @group misc
$badgeMinWidth: 1.5rem;

//// Height of a badge
//// @group misc
$badgeHeight: 1.5rem;

//// Font weight of a badge
//// @group misc
$badgeFontWeight: 700;

//// Font size of a badge
//// @group misc
$badgeFontSize: 0.75rem;

//// Padding of a badge
//// @group misc
$tagPadding: 0.25rem 0.4rem;

//// Height of a progress bar
//// @group misc
$progressBarHeight: 1.5rem;

//// Border of a progress bar
//// @group misc
$progressBarBorder: 0 none;

//// Background of a progress bar
//// @group misc
$progressBarBg: #dee2e6;

//// Background of a progress bar value
//// @group misc
$progressBarValueBg: $primaryColor;

//// Background of an avatar
//// @group misc
$avatarBg: #dee2e6;

//// Text color of an avatar
//// @group misc
$avatarTextColor: $textColor;

//// Background of a chip
//// @group misc
$chipBg: #dee2e6;

//// Text color of a chip
//// @group misc
$chipTextColor: $textColor;

//// Border radius of a chip
//// @group misc
$chipBorderRadius: 16px;

//// Background of a scrollTop
//// @group misc
$scrollTopBg: $buttonBg;

//// Background of a scrollTop in hover state
//// @group misc
$scrollTopHoverBg: $buttonHoverBg;

//// Width of a scrollTop
//// @group misc
$scrollTopWidth: 3rem;

//// Height of a scrollTop
//// @group misc
$scrollTopHeight: 3rem;

//// Border radius of a scrollTop
//// @group misc
$scrollTopBorderRadius: 50%;

//// Font size of a scrollTop
//// @group misc
$scrollTopFontSize: 1.5rem;

//// Text color of a scrollTop
//// @group misc
$scrollTopTextColor: #f8f9fa;

//// Background of a skeleton
//// @group misc
$skeletonBg: #e9ecef;

//// Background of a skeleton animation
//// @group misc
$skeletonAnimationBg: rgba(255, 255, 255, 0.4);
